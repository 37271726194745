import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from 'next/link';
import _camelCase from 'lodash/camelCase';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import AccessibilityNew from '@material-ui/icons/AccessibilityNew';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Stars from '@material-ui/icons/Stars';
import Star from '@material-ui/icons/Star';
import LockIcon from '@material-ui/icons/Lock';
import HowToReg from '@material-ui/icons/HowToReg';
import { eventAgeRestrictions, eventTypes, prettyVisibility } from '../../utils/events';
import Tag from '../tag';

const useStyles = makeStyles((theme) => ({
  tags: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5, 0),
    },
  },
  featuredIcon: {
    color: theme.palette.secondary.main,
  },
  tagSoloIcon: {
    marginRight: '0 !important',
  },
  compactChipLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 150,
  },
  notPublic: {
    borderStyle: 'dashed',
  },
}));

const EventHeaderTags = (props) => {
  const { event, group, className, showVisibility, highlightAdminEvents } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.tags, className)}>
      <Tag color={_camelCase(event.eventType)}>{eventTypes[event.eventType].name}</Tag>
      {highlightAdminEvents && event.hostType === 'Group' && (
        <Tag color="primary">
          <Stars />
          Group Hosted
        </Tag>
      )}
      {event.ageRestriction === '21_PLUS' && (
        <Tag color="ageRestriction">
          <SupervisedUserCircle fontSize="small" className={classes.icon} />
          <span className={classes.compactChipLabel} title={`${eventAgeRestrictions[event.ageRestriction].name} (${eventAgeRestrictions[event.ageRestriction].description})`}>
            21+
          </span>
        </Tag>
      )}
      {event.audienceRestriction && (
        <Tag color="audienceRestriction">
          <AccessibilityNew fontSize="small" className={classes.icon} />
          <span className={classes.compactChipLabel} title={event.audienceRestriction}>
            {event.audienceRestriction}
          </span>
        </Tag>
      )}
      {event.series && (
        <Link
          href="/event-series/[id]"
          as={`/event-series/${event.series.id}`}
          legacyBehavior
        >
          <Tag component="a" href={`/event-series/${event.series.id}`}>Series</Tag>
        </Link>
      )}
      {event.registrationType === 'EXTERNAL_REGISTRATION' && (
        <Tag
          color="registrationRequired"
          title="External Registration Required"
        >
          <ConfirmationNumber fontSize="small" className={classes.tagSoloIcon} />
        </Tag>
      )}
      {event.registrationType === 'APPROVAL_REQUIRED' && (
        <Tag
          color="approvalRequired"
          title="Approval Required"
        >
          <HowToReg fontSize="small" className={classes.tagSoloIcon} />
        </Tag>
      )}
      {showVisibility && (
        <Tag
          variant="outlined"
          className={clsx(classes.iconChip, { [classes.notPublic]: event.visibility !== 'PUBLIC' })}
        >
          {event.visibility !== 'PUBLIC' && (
            <LockIcon className={classes.icon} fontSize="small" />
          )}
          {prettyVisibility(event)}
        </Tag>
      )}
      {group && event.groupFeatured && (
        <Star className={classes.featuredIcon} />
      )}
      {event.status === 'DRAFT' && (
        <Tag color="orange">Draft</Tag>
      )}
      {event.status === 'GROUP_PENDING' && (
        <Tag color="red">
          Pending Approval
        </Tag>
      )}
      {event.status === 'CANCELLED' && (
        <Tag color="red">Cancelled</Tag>
      )}
    </div>
  );
};

EventHeaderTags.propTypes = {
  event: PropTypes.object.isRequired,
  group: PropTypes.object,
  className: PropTypes.string,
  highlightAdminEvents: PropTypes.bool,
  showVisibility: PropTypes.bool,
};

EventHeaderTags.defaultProps = {
  highlightAdminEvents: false,
  showVisibility: false,
};

export default EventHeaderTags;

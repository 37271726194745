import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopy from '@material-ui/icons/FileCopy';
import Clipboard from 'clipboard';

const CopyButton = ({ text, name, containerRef, ...rest }) => {
  const clipboard = useRef();
  const clipboardButtonRef = useRef();
  const [copied, setCopied] = useState(false);
  const copiedTimer = useRef();

  useEffect(() => {
    if (clipboardButtonRef.current) {
      clipboard.current = new Clipboard(clipboardButtonRef.current, containerRef ? {
        container: containerRef.current,
      } : null);
      clipboard.current.on('success', () => {
        console.log('copied', text)
        setCopied(true);
      });

      return () => {
        clipboard.current.destroy();
      };
    }
  }, []);

  useEffect(() => {
    if (copied.current) {
      clearTimeout(copied.current);
    }
    copiedTimer.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [copied]);

  if (typeof document === 'undefined' || !Clipboard.isSupported()) {
    return null;
  }

  return (
    <Tooltip title={`Copied${name && ` ${name}`}`} arrow open={copied}>
      <IconButton
        ref={clipboardButtonRef}
        data-clipboard-text={text}
        data-aria-label={`Copy${name && ` ${name}`}`}
        {...rest}
      >
        <FileCopy />
      </IconButton>
    </Tooltip>
  );
};

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  containerRef: PropTypes.any,
};

export default CopyButton;

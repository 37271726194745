import { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MLink from '@material-ui/core/Link';
import { updateEventGroupApprovalMutation } from '../../apollo/events';
import RadioGroupField from '../forms/radio-group-field';
import TextAreaField from '../forms/text-area-field';
import Host from '../host';
import MaterialLink from '../material-link';
import { momentParse } from '../../utils/time';

const useStyles = makeStyles((theme) => ({
  eventName: {
    fontWeight: 'bold',
  },
  textArea: {
    minWidth: 400,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
}));

const formSchema = Yup.object().shape({
  approved: Yup.bool().required('Approved is required'),
  groupDeniedMessage: Yup.string().nullable(),
});

const GroupPendingButton = (props) => {
  const { event, buttonComponent: ButtonComponent, onSuccess, ...rest } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleCancel = useCallback((e) => {
    e.preventDefault();
    setOpen(false);
  }, []);

  const [updateEventGroupApproval] = useMutation(updateEventGroupApprovalMutation);

  const handleSubmit = useCallback((values) => {
    updateEventGroupApproval({
      variables: {
        id: event.id,
        data: values,
      },
    }).then(() => {
      setOpen(false);
      if (onSuccess) {
        onSuccess();
      }
    });
  }, [event, onSuccess]);

  return (
    <Fragment>
      <ButtonComponent onClick={handleClickOpen} {...rest}>
        Respond to Approval Request
      </ButtonComponent>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="pending-approval-title"
        aria-describedby="pending-approval-description"
      >
        <Formik
          initialValues={{
            approved: true,
            groupDeniedMessage: '',
          }}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <DialogTitle id="pending-approval-title">Approve this event?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <MaterialLink
                    href="/events/[id]"
                    as={`/events/${event.id}`}
                    className={classes.eventName}
                  >
                    {event.name} ({momentParse(event.startAt, event.timezone).format('MMMM D, YYYY h:mm a')})
                  </MaterialLink>
                  <Host event={event} />
                </DialogContentText>
                <RadioGroupField
                  name="approved"
                  options={[
                    { value: true, label: 'Approve' },
                    { value: false, label: 'Deny' },
                  ]}
                />
                {!values.approved && (
                  <TextAreaField
                    name="groupDeniedMessage"
                    label="Denied Message (optional)"
                    className={classes.textArea}
                    helperText={(
                      <span>
                        <MLink href="https://www.markdownguide.org/basic-syntax/" target="_blank">
                          Markdown
                        </MLink>
                        {' '}is supported
                      </span>
                    )}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
};

GroupPendingButton.propTypes = {
  event: PropTypes.object.isRequired,
  buttonComponent: PropTypes.any,
  onSuccess: PropTypes.func,
};

GroupPendingButton.defaultProps = {
  buttonComponent: Button,
};

export default GroupPendingButton;

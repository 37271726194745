import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import config from '../config';
import CopyButton from './copy-button';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  shareButton: {
    height: 32,
    width: 32,
  },
  copyButton: {
    padding: 8,
    backgroundColor: '#7f7f7f',
    color: '#fff',
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
    '&:hover': {
      backgroundColor: '#7f7f7f',
    }
  },
}));

const iconProps = {
  round: true,
  size: 32,
};

const ShareButtons = ({ title, path, className }) => {
  const classes = useStyles();
  const containerRef = useRef();

  const url = `https://everydayrides.com${path}`;

  return (
    <div className={clsx(classes.wrapper, className)} ref={containerRef}>
      <FacebookShareButton
        url={url}
        className={classes.shareButton}
        aria-label="Share on Facebook"
      >
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        appId={config().universal.facebookMessengerShareAppId}
        url={url}
        className={classes.shareButton}
        aria-label="Share via Facebook Messenger"
      >
        <FacebookMessengerIcon {...iconProps} />
      </FacebookMessengerShareButton>
      <TwitterShareButton
        title={title}
        url={url}
        related={['everyday_rides']}
        className={classes.shareButton}
        aria-label="Share on Twitter"
      >
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>
      <EmailShareButton
        subject={title}
        url={url}
        className={classes.shareButton}
        aria-label="Share via Email"
      >
        <EmailIcon {...iconProps} />
      </EmailShareButton>
      <CopyButton
        name="URL"
        text={url}
        className={classes.copyButton}
        disableFocusRipple
        disableRipple
        containerRef={containerRef}
      />
    </div>
  );
};

ShareButtons.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ShareButtons;

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import clsx from 'clsx';
import _includes from 'lodash/includes';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MaterialLink from './material-link';
import MaterialLinkButton from './material-link-button';
import Host from './host';
import EventTime from './events/time';
import RsvpAttendeesSummary from './events/rsvp-attendees-summary';
import EventCategoryTags from './events/category-tags';
import LocationCompact from './events/location-compact';
import ImageUpload from './image-upload';
import ShareButton from './events/share-button';
import EventEditDropdown from './events/edit-dropdown';
import EventHeaderTags from './events/header-tags';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  imageCol: {
    '&.no-image': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  detailsCol: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  rsvpCol: {
    width: 160,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: theme.spacing(1.5),
    },
  },
  imageLink: {
    width: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  imagePlaceholder: {
    width: 160,
    height: 0,
  },
  eventTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  eventName: {
    margin: theme.spacing(0, 1, 0, 0),
    fontSize: 18,
  },
  headerTags: {
    maxWidth: 300,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  host: {
    margin: '1px 0',
    '& > *': {
      marginBottom: 2,
    },
  },
  date: {
    margin: theme.spacing(1, 0),
  },
  manageButtons: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '& > *': {
      margin: theme.spacing(0, 0, 1, 0),
      '&:not(:last-child)': {
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1),
        },
      },
    },
  },
  iconRow: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),
  },
  rowIcon: {
    marginRight: theme.spacing(0.5),
    color: '#333',
  },
  metaIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    opacity: 0.6
  },
  locationHidden: {
    fontStyle: 'italic',
  },
  summary: {
    marginTop: 10,
  },
}));

const EventListing = ({ event, fullDate, highlightAdminEvents, group }) => {
  const classes = useStyles();

  // in case event is deleted but then viewing cached lists
  if (event.status === 'DELETED') {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.imageCol, { 'no-image': !event.image })}>
        <Link
          href="/events/[id]"
          as={`/events/${event.id}`}
          title={event.name}
          className={classes.imageLink}
        >
          {event.image ? (
            <ImageUpload record={event} size="160x120iwe" />
          ) : (
            <div className={classes.imagePlaceholder} />
          )}
        </Link>
      </div>
      <div className={classes.detailsCol}>
        <div className={classes.eventTitle}>
          <Typography variant="h3" className={classes.eventName}>
            <MaterialLink href="/events/[id]" as={`/events/${event.id}`}>
              {event.name}
            </MaterialLink>
          </Typography>
          <EventHeaderTags
            event={event}
            group={group}
            highlightAdminEvents={highlightAdminEvents}
            className={classes.headerTags}
          />
        </div>
        <div className={classes.iconRow}>
          <ScheduleIcon fontSize="small" className={classes.rowIcon} /> 
          <EventTime event={event} fullDate={fullDate} />
        </div>
        <div className={classes.iconRow}>
          <LocationOnIcon fontSize="small" className={classes.rowIcon} />
          {(event.registrationType === 'APPROVAL_REQUIRED' && !event.permissions.approved)
            ? <span className={classes.locationHidden}>Location hidden</span>
            : (
              event.eventType === 'ONLINE_EVENT'
                ? 'Online Event'
                : (
                  <LocationCompact
                    name={event.startLocation}
                    address={event.startLocationAddress}
                    details={event.startLocationDetails}
                  />
                )
            )
          }
        </div>
        <Host event={event} className={classes.host} />
        {event.summary && (
          <p className={classes.summary}>
            {event.summary}
            {event.summary.substring(event.summary.length - 3) === '...' && (
              <Fragment>
                {' '}
                <MaterialLink href="/events/[id]" as={`/events/${event.id}`}>
                  read more
                </MaterialLink>
              </Fragment>
            )}
          </p>
        )}
        <EventCategoryTags event={event} compact />
      </div>
      <div className={classes.rsvpCol}>
        <div className={classes.date}>
          {event.permissions && !event.permissions.joinable && event.registrationType === 'APPROVAL_REQUIRED' &&
            (
              !event.membership ||
              !_includes(['GOING', 'INTERESTED', 'NOT_GOING'], event.membership.status)
            ) && (
            <div className={classes.memberSummary}>
              <MaterialLinkButton
                href="/events/[id]"
                as={`/events/${event.id}`}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Request to join
                {event.membership && event.membership.status === 'REQUESTED' && ' – Pending'}
              </MaterialLinkButton>
            </div>
          )}
          {event.permissions && event.permissions.joinable && (
            <RsvpAttendeesSummary event={event} />
          )}
        </div>
        <div className={classes.manageButtons}>
          <ShareButton
            event={event}
            size="small"
            variant="outlined"
          />
          {event.permissions && event.permissions.editable && (
            <EventEditDropdown
              size="small"
              variant="outlined"
              event={event}
              group={group}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EventListing.propTypes = {
  event: PropTypes.object,
  group: PropTypes.object,
  fullDate: PropTypes.bool,
  highlightAdminEvents: PropTypes.bool,
};

EventListing.defaultProps = {
  fullDate: false,
  highlightAdminEvents: false,
};

export default EventListing;

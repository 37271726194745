import { useState, useRef, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import MessageIcon from '@material-ui/icons/Message';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hoverable: {
    marginLeft: theme.spacing(0.5),
    color: '#999',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    padding: theme.spacing(1),
    pointerEvents: 'auto',
  },
}));

const PopoverIcon = (props) => {
  const { children, iconComponent: IconComponent } = props;
  const classes = useStyles();
  const hoverRef = useRef();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <Fragment>
      <IconComponent
        fontSize="small"
        ref={hoverRef}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        aria-haspopup="true"
        aria-owns="details-popover"
        className={classes.hoverable}
      />
      <Popover
        id='details-popover'
        anchorEl={hoverRef.current}
        open={open}
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        PaperProps={{
          onMouseEnter: handleOpen,
          onMouseLeave: handleClose,
        }}
      >
        {children}
      </Popover>
    </Fragment>
  );
};

PopoverIcon.propTypes = {
  children: PropTypes.node,
  iconComponent: PropTypes.func,
};

PopoverIcon.defaultProps = {
  iconComponent: MessageIcon,
};

export default PopoverIcon;

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { momentParse, timeRange, dateAndTimeRange } from '../../utils/time';
import PopoverIcon from '../popover-icon';

const EventTime = ({ event, fullDate, ...otherProps }) => {
  if (!event.startAt) {
    return <div>TBD</div>;
  }

  const startAt = momentParse(event.startAt, event.timezone);
  const endAt = event.endAt ? momentParse(event.endAt, event.timezone) : null;
  const details = event.startAtDetails;

  return (
    <Fragment>
      <time
        dateTime={startAt.toString()}
        title={startAt.format('dddd, MMMM Do, YYYY h:mm a')}
        {...otherProps}
      >
        {fullDate ? dateAndTimeRange(startAt, endAt) : timeRange(startAt, endAt)}
      </time>
      {details && <PopoverIcon>{details}</PopoverIcon>}
    </Fragment>
  );
};

EventTime.propTypes = {
  event: PropTypes.object,
  fullDate: PropTypes.bool,
};

EventTime.defaultProps = {
  fullDate: false,
};

export default EventTime;

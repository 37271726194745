import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import _sortBy from 'lodash/sortBy';
import _isFinite from 'lodash/isFinite';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import SpeedIcon from '@material-ui/icons/Speed';
import PanToolIcon from '@material-ui/icons/PanTool';
import LockIcon from '@material-ui/icons/Lock';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import AccessibilityNew from '@material-ui/icons/AccessibilityNew';
import HowToReg from '@material-ui/icons/HowToReg';
import ChatBubble from '@material-ui/icons/ChatBubble';
import { getEventCategoryName, getEventCategoryIdsForType } from '../../utils/event-categories';
import {
  eventTypes,
  eventPaces,
  eventDistances,
  eventRegroups,
  eventTerrains,
  eventWeatherCancellations,
  eventAgeRestrictions,
  prettyVisibility,
 } from '../../utils/events';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: theme.spacing(2, 0, 1, 0),
  },
  tag: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  rideIcon: {
    filter: 'grayscale(100%)',
    width: 24,
    marginRight: theme.spacing(0.5),
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: 16,
  },
  iconChip: {
    maxWidth: '100%',
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  notPublic: {
    borderStyle: 'dashed',
  },
  compactChipLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 300,
  },
}));

const EventCategoryTags = ({ event, compact }) => {
  const classes = useStyles();

  const {
    eventType,
    visibility,
    categoryIds,
    loop,
    pace,
    distance,
    terrain,
    regroup,
    weatherCancellation,
    ageRestriction,
    audienceRestriction,
    registrationType,
  } = event;

  const categoryIdsForType = getEventCategoryIdsForType(eventType);
  const sortedCategoryIds = _sortBy(categoryIds, (categoryId) => (
    categoryIdsForType.indexOf(categoryId)
  ));

  return (
    <div className={classes.wrapper}>
      <span className={classes.tag}>
        <Chip
          label={(
            <Fragment>
              {visibility !== 'PUBLIC' && (
                <LockIcon className={classes.icon} fontSize="small" />
              )}
              {prettyVisibility(event)} {eventTypes[eventType].name}
            </Fragment>
          )}
          variant="outlined"
          size="small"
          className={clsx(classes.iconChip, { [classes.notPublic]: visibility !== 'PUBLIC' })}
        />
      </span>
      {ageRestriction !== 'GENERAL' && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <SupervisedUserCircle fontSize="small" className={classes.icon} />
                <span
                  className={clsx({ [classes.compactChipLabel]: compact })}
                  title={eventAgeRestrictions[ageRestriction].description}
                >
                  {eventAgeRestrictions[ageRestriction].name} ({eventAgeRestrictions[ageRestriction].description})
                </span>
              </Fragment>
            )}
            size="small"
            variant="outlined"
            className={classes.iconChip}
          />
        </span>
      )}
      {audienceRestriction && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <AccessibilityNew fontSize="small" className={classes.icon} />
                {audienceRestriction}
              </Fragment>
            )}
            size="small"
            variant="outlined"
            className={classes.iconChip}
          />
        </span>
      )}
      {registrationType === 'EXTERNAL_REGISTRATION' && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <ConfirmationNumber fontSize="small" className={classes.icon} />
                External Registration Required
              </Fragment>
            )}
            size="small"
            variant="outlined"
            className={classes.iconChip}
          />
        </span>
      )}
      {registrationType === 'APPROVAL_REQUIRED' && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <HowToReg fontSize="small" className={classes.icon} />
                Approval Required
              </Fragment>
            )}
            size="small"
            variant="outlined"
            className={classes.iconChip}
          />
        </span>
      )}
      {loop && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <AllInclusiveIcon fontSize="small" className={classes.icon} />
                Loop
              </Fragment>
            )}
            variant="outlined"
            size="small"
            className={classes.iconChip}
          />
        </span>
      )}
      {pace && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <SpeedIcon fontSize="small" className={classes.icon} />
                {eventPaces[pace].name} ({eventPaces[pace].description})
              </Fragment>
            )}
            variant="outlined"
            size="small"
            className={classes.iconChip}
          />
        </span>
      )}
      {distance && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <SpeedIcon fontSize="small" className={classes.icon} />
                {eventDistances[distance].name}
              </Fragment>
            )}
            variant="outlined"
            size="small"
            className={classes.iconChip}
          />
        </span>
      )}
      {terrain && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <FilterHdrIcon fontSize="small" className={classes.icon} />
                <span
                  className={clsx({ [classes.compactChipLabel]: compact })}
                  title={eventTerrains[terrain].description}
                >
                  {eventTerrains[terrain].name} ({eventTerrains[terrain].description})
                </span>
              </Fragment>
            )}
            variant="outlined"
            size="small"
            className={classes.iconChip}
          />
        </span>
      )}
      {regroup && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <PanToolIcon fontSize="small" className={classes.icon} />
                {eventRegroups[regroup].name}
              </Fragment>
            )}
            variant="outlined"
            size="small"
            className={classes.iconChip}
          />
        </span>
      )}
      {weatherCancellation && (
        <span className={classes.tag}>
          <Chip
            label={(
              <Fragment>
                <WbSunnyIcon fontSize="small" className={classes.icon} />
                {eventWeatherCancellations[weatherCancellation].name}
              </Fragment>
            )}
            variant="outlined"
            size="small"
            className={classes.iconChip}
          />
        </span>
      )}
      <Fragment>
        {sortedCategoryIds.map((categoryId) => {
          return (
            <span key={categoryId} className={classes.tag}>
              <Chip
                label={getEventCategoryName(categoryId)}
                size="small"
                variant="outlined"
              />
            </span>
          );
        })}
      </Fragment>
      {((_isFinite(event.commentCount) && event.commentCount > 0) || (_isFinite(event.imageUploadsCount) && event.imageUploadsCount > 0)) && (
        <span className={classes.tag}>
          <Link href="/events/[id]" as={`/events/${event.id}`} legacyBehavior>
            <Chip
              label={(
                <Fragment>
                  <ChatBubble fontSize="small" className={classes.icon} />
                  {(event.commentCount || 0) + (event.imageUploadsCount || 0)}
                </Fragment>
              )}
              variant="outlined"
              size="small"
              className={classes.iconChip}
              clickable
              component="a"
              href={`/events/${event.id}`}
            />
          </Link>
        </span>
      )}
    </div>
  );
};

EventCategoryTags.propTypes = {
  event: PropTypes.object,
  compact: PropTypes.bool,
};

EventCategoryTags.defaultProps = {
  compact: false,
};

export default EventCategoryTags;

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import _omit from 'lodash/omit';
import Link from 'next/link';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { updateEventStatusMutation, newMemberFragment } from '../../apollo/events';
import { useAppContext } from '../../contexts/app';
import RsvpDropdown from './rsvp-dropdown';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(0.5)
    }
  },
  goingIcon: {
    fontSize: 18,
    color: '#555',
    marginRight: theme.spacing(0.5)
  },
  interestedIcon: {
    fontSize: 18,
    color: '#555',
    marginRight: theme.spacing(0.5)
  },
  notGoingIcon: {
    fontSize: 18,
    color: '#555'
  },
  selectedStatus: {
    textDecoration: 'underline'
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  separator: {
    margin: theme.spacing(0, 0.5)
  },
  changeIcon: {
    fontSize: 22,
    lineHeight: '22px',
    color: '#555',
    marginLeft: 0
  },
  summary: {
    marginTop: theme.spacing(1),
  },
  summaryLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const RSVPAttendeesSummary = (props) => {
  const { event } = props;
  const { memberSummary, membership } = event;
  const classes = useStyles();
  const { isLoggedIn, login } = useAppContext();

  const [updateEventStatus] = useMutation(updateEventStatusMutation, {
    update: (cache, { data: { updateEventStatus: newMember } }) => {
      // don't add to cache if already there because 
      if (membership) {
        return;
      }

      // update event w/ updated membership object
      try {
        cache.writeFragment({
          id: `Event:${event.id}`,
          fragment: newMemberFragment,
          data: {
            id: event.id,
            __typename: 'Event',
            membership: {
              ..._omit(newMember, ['event']),
              __typename: 'EventMember',
            },
          },
        });
      } catch (e) {
        console.log(e);
        // nothing
      }
    }
  });

  const handleRsvpChange = useCallback((newStatus) => {
    // TODO: !isLoggedIn || !event.permissions || !event.permissions.joinable

    // // build returnTo
    if (!isLoggedIn) {
      login(`/events/${event.id}?rsvp=${newStatus}`);
      return;
    }

    // if (!eventInvite) {
    //   if (event.approvalRequired) {
    //     return (
    //       <div className={classes.wrapper}>
    //         <p>Host approval required to RSVP</p>
    //         <RequestJoinButton
    //           event={event}
    //           returnTo={returnTo}
    //           onJoin={onJoin}
    //         />
    //       </div>
    //     );
    //   }

    // TODO: alert if login error returned from API
    updateEventStatus({
      variables: {
        eventId: event.id,
        status: newStatus,
      }
    })
      .catch(() => {
        alert('Something went wrong. Please try again.');
      });
  }, [event]);

  if (!memberSummary) {
    return null;
  }

  const status = get(membership, 'status');

  return (
    <div>
      <RsvpDropdown
        value={status}
        onChange={handleRsvpChange}
      />
      <div className={classes.summary}>
        <Link
          href="/events/[id]/attendees"
          as={`/events/${event.id}/attendees`}
          className={classes.summaryLink}
        >
          {memberSummary.GOING.count} Going{' — '}
          {memberSummary.INTERESTED.count} Interested
        </Link>
      </div>
    </div>
  );
};

RSVPAttendeesSummary.propTypes = {
  event: PropTypes.object,
  className: PropTypes.string
};

export default RSVPAttendeesSummary;

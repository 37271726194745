import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import _compact from 'lodash/compact';
import _trim from 'lodash/trim';
import _sortBy from 'lodash/sortBy';
import _each from 'lodash/each';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EventIcon from '@material-ui/icons/Event';
import removeMarkdown from 'remove-markdown';
import moment from 'moment';
import config from '../../config';
import { eventAgeRestrictions, eventDistances, eventPaces, eventRegroups, eventTerrains, eventTypes, eventWeatherCancellations, prettyVisibility } from '../../utils/events';
import { getEventCategoryIdsForType, getEventCategoryName } from '../../utils/event-categories';

const useStyles = makeStyles((theme) => ({
  trigger: {
    display: 'flex',
    alignItems: 'center',
  },
  eventIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

const buildEventDescription = (event) => {
  const { eventType, user, group, hostType } = event;
  const { approved } = event.permissions;

  const description = [];

  // host
  let host;
  if (hostType === 'Group') {
    host = group.name;
  } else if (hostType === 'User') {
    const displayName = (user.givenName || user.familyName) ?
      _compact([user.givenName, user.familyName]).join(' ') :
      user.username;
    host = `${displayName}${group ? ` in ${group.name}` : ''}`;
  }
  description.push(`Hosted by:\n${host}`);

  if (approved) {
    // start time details
    if (event.startAtDetails) {
      description.push(`Start Details:\n${event.startAtDetails}`);
    }

    // location
    if (event.startLocation) {
      description.push(`${eventType === 'RIDE' ? 'Start ' : ''}Location:\n${_compact([
        event.startLocation,
        event.startLocationAddress,
        event.startLocationDetails,
      ]).join("\n")}`);
    }
    if (eventType === 'RIDE' && event.endLocation) {
      description.push(`End Location:\n${_compact([
        event.endLocation,
        event.endLocationAddress,
        event.endLocationDetails,
      ]).join("\n")}`);
    }
  }

  // description, stripped of markdown
  if (event.description) {
    description.push(removeMarkdown(_trim(event.description)));
  }
  if (approved && event.approvalDescription) {
    description.push(removeMarkdown(_trim(event.approvalDescription)));
  }

  // category tags
  const categoryTags = [
    prettyVisibility(event, { group }),
    eventTypes[eventType].name,
  ];
  if (event.ageRestriction !== 'GENERAL') {
    categoryTags.push(`${eventAgeRestrictions[event.ageRestriction].name} (${eventAgeRestrictions[event.ageRestriction].description})`);
  }
  if (event.audienceRestriction) {
    categoryTags.push(event.audienceRestriction);
  }
  if (event.registrationType === 'EXTERNAL_REGISTRATION') {
    categoryTags.push('External Registration Required');
  }
  if (event.registrationType === 'APPROVAL_REQUIRED') {
    categoryTags.push('Approval Required');
  }
  if (event.loop) {
    categoryTags.push('Loop');
  }
  if (event.pace) {
    categoryTags.push(`${eventPaces[event.pace].name} (${eventPaces[event.pace].description})`);
  }
  if (event.distance) {
    categoryTags.push(eventDistances[event.distance].name);
  }
  if (event.terrain) {
    categoryTags.push(`${eventTerrains[event.terrain].name} (${eventTerrains[event.terrain].description})`);
  }
  if (event.regroup) {
    categoryTags.push(eventRegroups[event.regroup].name);
  }
  if (event.weatherCancellation) {
    categoryTags.push(eventWeatherCancellations[event.weatherCancellation].name);
  }
  const categoryIdsForType = getEventCategoryIdsForType(eventType);
  const sortedCategoryIds = _sortBy(event.categoryIds, (categoryId) => categoryIdsForType.indexOf(categoryId));
  _each(sortedCategoryIds, (categoryId) => {
    categoryTags.push(getEventCategoryName(categoryId));
  });
  description.push(categoryTags.join(', '));

  if (approved) {
    // info url
    if (event.infoUrl) {
      description.push(`Learn More:\n${event.infoUrl}`);
    }

    // route url
    if (event.routeUrl) {
      description.push(`Route:\n${event.routeUrl}`);
    }
  }

  // contact
  if (event.contactName || event.contactPhoneNumberDisplay || event.contactEmail) {
    const contact = [];
    if (event.contactName) {
      contact.push(event.contactName);
    }
    if (event.contactEmail) {
      contact.push(event.contactEmail);
    }
    if (event.contactPhoneNumberDisplay) {
      contact.push(event.contactPhoneNumberDisplay);
    }
    description.push(`Contact:\n${contact.join('\n')}`);
  }

  const eventUrl = `${config().universal.baseUri}/events/${event.id}`;
  description.push(`More details and RSVP:\n${eventUrl}`);

  return description.join('\n\n');
};

const AddToCalendar = ({ event, buttonComponent: ButtonComponent, className, ...rest }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  if (!event.startAt) {
    return null;
  }

  function handleOpen(event) {
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSelect(item) {
    if (item === 'google') {
      const startAt = moment.utc(event.startAt);
      const endAt = event.endAt ? moment.utc(event.endAt) : startAt;
      const googleFormat = 'YYYYMMDDTHHmmss';

      const location = event.permissions.approved ?
        _compact([
          event.startLocation,
          event.startLocationAddress,
        ]).join(', ') :
        'Location hidden';

      window.location = `https://www.google.com/calendar/render?${[
        'action=TEMPLATE',
        `text=${encodeURI(event.name)}`,
        `dates=${startAt.format(googleFormat)}Z/${endAt.format(googleFormat)}Z`,
        `ctz=${event.timezone}`,
        `details=${encodeURI(buildEventDescription(event))}`,
        `location=${location}`
      ].join('&')}`;
      return;
    }
    window.location = `${config().universal.baseUri}/events/${event.id}/event.ics?token=${event.exportToken}`;
  }

  return (
    <Fragment>
      <ButtonComponent
        aria-controls="add-to-calendar"
        aria-haspopup="true"
        onClick={handleOpen}
        className={clsx(classes.trigger, className)}
        {...rest}
      >
        <EventIcon className={classes.eventIcon} />
        <span>Add to Calendar</span>
      </ButtonComponent>
      <Menu
        id="add-to-calendar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSelect('google')}>Add to Google Calendar</MenuItem>
        <MenuItem onClick={() => handleSelect('outlook')}>Add to Outlook Calendar</MenuItem>
        <MenuItem onClick={() => handleSelect('apple')}>Add to Apple Calendar</MenuItem>
        <MenuItem onClick={() => handleSelect('ics')}>Download .ics</MenuItem>
      </Menu>
    </Fragment>
  );
};

AddToCalendar.propTypes = {
  event: PropTypes.object.isRequired,
  buttonComponent: PropTypes.elementType,
  className: PropTypes.string,
};

AddToCalendar.defaultProps = {
  buttonComponent: Button,
};

export default AddToCalendar;

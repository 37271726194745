import { useRef, useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import clsx from 'clsx';
import _sortBy from 'lodash/sortBy';
import _reject from 'lodash/reject';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Schedule from '@material-ui/icons/Schedule';
import Settings from '@material-ui/icons/Settings';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { updateEventGroupFeaturedMutation } from '../../apollo/events';
import { groupQuery } from '../../apollo/groups';
import GroupPendingButton from './group-pending-button';
import CopyEventButton from './copy-event-button';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  featuredIcon: {
    color: theme.palette.secondary.main,
  },
}));

const EventEditDropdown = (props) => {
  const { event, group, ...rest } = props;
  const classes = useStyles();
  const buttonRef = useRef();

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const [updateEventGroupFeatured] = useMutation(updateEventGroupFeaturedMutation);
  const handleToggleGroupFeatured = useCallback(() => {
    updateEventGroupFeatured({
      variables: {
        id: event.id,
        groupFeatured: !event.groupFeatured,
      },
      update: (proxy, { data: { updateEventGroupFeatured: updatedEvent } }) => {
        const groupQueryVariables = {
          slug: event.group.slug,
        };
        const data = proxy.readQuery({
          query: groupQuery,
          variables: groupQueryVariables,
        });
        const featuredUpcomingEvents = updatedEvent.groupFeatured ?
          _sortBy([...data.group.featuredUpcomingEvents, updatedEvent], 'startAt') :
          _reject(data.group.featuredUpcomingEvents, (e) => e.id === updatedEvent.id);
        proxy.writeQuery({
          query: groupQuery,
          variables: groupQueryVariables,
          data: {
            ...data,
            group: {
              ...data.group,
              featuredUpcomingEvents,
            },
          },
        });
      }
    }).then(() => {
      setOpen(false);
    });
  }, [event]);

  return (
    <Fragment>
      <Button
        aria-controls={`edit-dropdown-${event.id}`}
        aria-haspopup="true"
        onClick={handleOpen}
        ref={buttonRef}
        {...rest}
      >
        Edit <Settings className={classes.icon} />
      </Button>
      <Menu
        id={`edit-dropdown-${event.id}`}
        anchorEl={buttonRef.current}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Link href="/events/[id]/edit" as={`/events/${event.id}/edit`} legacyBehavior>
          <MenuItem
            component="a"
            href={`/events/${event.id}/edit`}
          >
            Edit Event
          </MenuItem>
        </Link>
        {event.series ? (
          <Link
            href="/event-series/[id]/schedule"
            as={`/event-series/${event.series.id}/schedule`}
            legacyBehavior
          >
            <MenuItem
              component="a"
              href={`/event-series/${event.series.id}/schedule`}
            >
              Manage Series <Schedule className={classes.icon} />
            </MenuItem>
          </Link>
        ) : (
          <Link href={`/event-series/new?convertEventId=${event.id}`} legacyBehavior>
            <MenuItem
              component="a"
              href={`/event-series/new?convertEventId=${event.id}`}
            >
              Convert to Series <Schedule className={classes.icon} />
            </MenuItem>
          </Link>
        )}
        <CopyEventButton
          buttonComponent={MenuItem}
          event={event}
        >
          Copy Event
        </CopyEventButton>
        {group && group.permissions.editable && (
          <MenuItem onClick={handleToggleGroupFeatured}>
            Mark as {event.groupFeatured ? 'Not ' : ''}Featured
            {event.groupFeatured
              ? <StarBorder className={clsx(classes.icon, classes.featuredIcon)} />
              : <Star className={clsx(classes.icon, classes.featuredIcon)} />}
          </MenuItem>
        )}
        {event.status === 'GROUP_PENDING' && group && group.permissions.editable && (
          <GroupPendingButton
            event={event}
            buttonComponent={MenuItem}
            onSuccess={handleClose}
          >
            Respond To Approval Request
          </GroupPendingButton>
        )}
      </Menu>
    </Fragment>
  );
};

EventEditDropdown.propTypes = {
  event: PropTypes.object.isRequired,
  group: PropTypes.object,
};

export default EventEditDropdown;

import PropTypes from 'prop-types';
import Main from "./main";

const NotFound = ({ compact }) => {
  const content = (
    <p>Not Found</p>
  );

  if (compact) {
    return content;
  }

  return (
    <Main>
      {content}
    </Main>
  );
};

NotFound.propTypes = {
  compact: PropTypes.bool,
};

NotFound.defaultProps = {
  compact: false,
};

export default NotFound;

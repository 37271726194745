import { useRef, useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import StarIcon from '@material-ui/icons/Star';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  button: {
    '& > span:first-child': {
      // paddingRight: theme.spacing(0.5),
    },
  },
  buttonValue: {
    display: 'flex',
    alignItems: 'center',
  },
  rsvpIcon: {
    marginRight: theme.spacing(0.5)
  },
}));

const options = {
  GOING: 'Going',
  INTERESTED: 'Interested',
  NOT_GOING: 'Not Going',
};

const RsvpDropdown = ({ value, onChange }) => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleItemClick = useCallback((newValue) => {
    onChange(newValue);
    handleClose();
  }, [onChange]);

  return (
    <Fragment>
      <Button
        aria-controls="rsvp-dropdown"
        aria-haspopup="true"
        onClick={handleOpen}
        ref={buttonRef}
        className={classes.button}
        variant="outlined"
        color="primary"
        fullWidth
      >
        <span className={classes.buttonValue}>
          {!value ? 'RSVP' : (
            <Fragment>
              {value === 'GOING' && (
                <CheckCircleIcon fontSize="small" className={classes.rsvpIcon} />
              )}
              {value === 'INTERESTED' && (
                <StarIcon fontSize="small" className={classes.rsvpIcon} />
              )}
              {value === 'NOT_GOING' && (
                <CancelIcon fontSize="small" className={classes.rsvpIcon} />
              )}
              {options[value]}
            </Fragment>
          )}
        </span>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="rsvp-dropdown"
        anchorEl={buttonRef.current}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {_map(options, (label, value) => (
          <MenuItem value={value} onClick={() => handleItemClick(value)} key={value}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

RsvpDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default RsvpDropdown;

import { useRef, useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Share from '@material-ui/icons/Share';
import { momentParse } from '../../utils/time';
import ShareButtons from '../share-buttons';
import AddToCalendar from './add-to-calendar';

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    padding: theme.spacing(1),
    pointerEvents: 'auto',
  },
  shareIcon: {
    fontSize: 20,
    marginLeft: theme.spacing(0.5),
  },
  addToCalendar: {
    marginBottom: theme.spacing(1),
  },
}));

const EventShareButton = (props) => {
  const { event, ...rest } = props;
  const classes = useStyles();

  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const momentStartAt = event.startAt ? momentParse(event.startAt, event.timezone) : null;

  return (
    <Fragment>
      <Button
        aria-controls="rsvp-dropdown"
        aria-haspopup="true"
        aria-describedby={`share-event-${event.id}`}
        onClick={handleOpen}
        ref={buttonRef}
        {...rest}
      >
        Share
        <Share className={classes.shareIcon} />
      </Button>
      <Popover
        id={`share-event-${event.id}`}
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popoverContent,
        }}
      >
        <AddToCalendar
          event={event}
          variant="outlined"
          color="primary"
          className={classes.addToCalendar}
        />
        <ShareButtons
          title={`${event.name}${momentStartAt ? ` on ${momentStartAt.format('ddd, MMMM D, YYYY')}` : ''}`}
          path={`/events/${event.id}`}
        />
      </Popover>
    </Fragment>
  );
};

EventShareButton.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventShareButton;

import PropTypes from 'prop-types';
import _compact from 'lodash/compact';
import clsx from 'clsx';
import MLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import PopoverIcon from '../popover-icon';

const useStyles = makeStyles(() => ({
  compact: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const EventLocationCompact = ({ name, address, details, className, ...rest }) => {
  const classes = useStyles();

  if (!name && !address) {
    return null;
  }

  const mapQuery = _compact([name, address]).join(', ');
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(mapQuery)}`;

  return (
    <div className={clsx(classes.compact, className)} {...rest}>
      <span>
        {name || address} (<MLink href={mapUrl} title={mapQuery}>map</MLink>)
      </span>
      {details && <PopoverIcon>{details}</PopoverIcon>}
    </div>
  );
};

EventLocationCompact.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  details: PropTypes.string,
  className: PropTypes.string,
};

export default EventLocationCompact;
